import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import md5 from 'md5'
import PropTypes from 'prop-types'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { format, parseISO } from 'date-fns'
import { BlogPageHeader, SimpleCallToActionBanner } from '../components'
import * as styles from '../styles/pages/blogTemplate'
import * as base from '../styles/base'
import PageLayout from '../layouts/PageLayout/PageLayout'


const propTypes = {
  data: PropTypes.shape({
    frontmatter: PropTypes.shape({
      path: PropTypes.string,
      title: PropTypes.string,
      introduction: PropTypes.string,
      tags: PropTypes.array,
      imgLink: PropTypes.string,
      date: PropTypes.string
    })
  }).isRequired
}

export default function BlogTemplate({ data }) {
  const { frontmatter, body, timeToRead } = data.mdx

  const entryDate = format(parseISO(frontmatter.date), 'MMMM d, yyyy')

  const authorEmailHash = md5(frontmatter.authorEmail)
  const defaultAvatar = encodeURI('https://s3.eu-central-1.amazonaws.com/aitoai-public/website-assets/avatar-default.png')
  const authorImgLink = `https://www.gravatar.com/avatar/${authorEmailHash}?s=500&d=${defaultAvatar}`
  const firstChild = (
    <BlogPageHeader
      blogTitle={frontmatter.title}
      blogTags={frontmatter.tags}
      coverImgLink={frontmatter.coverImgLink}
      coverImgCreditLink={frontmatter.coverImgCreditLink}
      coverImgCreditText={frontmatter.coverImgCreditText}
    />
  )
  return (
    <PageLayout
      firstChild={firstChild}
      displayNavbar={false}
      description={frontmatter.metaDescription}
      sharingImageLink={frontmatter.coverImgLink}
      sharingTitle={`Aito.ai - ${frontmatter.title}`}
    >
      <Helmet>
        <title>{`Aito.ai - ${frontmatter.title}`}</title>
      </Helmet>
      <styles.MetadataContainer>
        <styles.AuthorImg src={authorImgLink} alt="author" />
        <styles.BlogMetadata>
          <styles.AuthorName>{frontmatter.authorName}</styles.AuthorName>
          <styles.MetadataText>{frontmatter.authorProfession}</styles.MetadataText>
          <styles.MetadataText>{`${entryDate} • ${timeToRead} min read`}</styles.MetadataText>
        </styles.BlogMetadata>
      </styles.MetadataContainer>
      <styles.Container>
        <base.MdContainer>
          <MDXRenderer>
            {body}
          </MDXRenderer>
          <styles.StyledLink to="/blog">
            <styles.BackArrow />
            Back to blog list
          </styles.StyledLink>
        </base.MdContainer>
        <SimpleCallToActionBanner />
      </styles.Container>
    </PageLayout>
  )
}

BlogTemplate.propTypes = propTypes

export const pageQuery = graphql`
  query BlogPostByPath($id: String!) {
    mdx( id: { eq: $id } ) {
      id
      body
      timeToRead
      frontmatter {
        path
        title
        introduction
        metaDescription
        tags
        coverImgLink
        coverImgCreditLink
        coverImgCreditText
        date
        authorName
        authorProfession
        authorEmail
      }
    }
  }
`
