import styled from 'styled-components'
import { Link } from 'gatsby'
import { ArrowLeft } from '@styled-icons/feather'

export const PageWrapper = styled.div`
  background-color: white;
`
export const Container = styled.div`
  width: 100%;
`

export const MetadataContainer = styled.div`
`
export const BlogMetadata = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0 0 0;
  color: #4E4E4E;
`
export const AuthorImg = styled.img`
  display: block;
  background-color:#fff;
  position: absolute;
  width: 120px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  transform: translateY(-50%);
  border-radius: 50%;
  border: 5px solid white;
`
export const AuthorName = styled.p`
  font-size: 1.4rem;
  font-weight: 500;
  margin: 1rem 0 0 0;
`
export const MetadataText = styled.p`
  margin: 0.2rem 0 0 0;
  color: #888;
`
export const StyledLink = styled(Link)`
  font-size: 1rem;
  display: flex;
  align-items: center;
  margin-top: 80px;
`
export const BackArrow = styled(ArrowLeft)`
  width: 16px;
  height: 16px;
  margin-right: 5px;
`
